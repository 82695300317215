import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Welcome to Papaeya
        </p>
      <img src="/assets/images/papaya.webp" height="200" alt="Description of the image" />
        <p>
          Current project is under construction:
        </p>
        <a>
          Airplanetwork the Board Game
        </a>
      </header>
    </div>
  );
}

export default App;
